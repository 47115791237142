export const FETCH_GROSS_SALARY = "FETCH_GROSS_SALARY";
export const UPDATE_GROSS_SALARY = "UPDATE_GROSS_SALARY";
export const SET_COMPANY_ID = "SET_COMPANY_ID";
export const SET_COMPANY_NAME = "SET_COMPANY_NAME";
export const SET_COMPANY_IDS = "SET_COMPANY_IDS";
export const SET_USER_PERMISSIONS_TASKS = "SET_USER_PERMISSIONS_TASKS";
export const SET_MANAGER_PERMISSIONS_TASKS = "SET_MANAGER_PERMISSIONS_TASKS";
export const SET_USER_PERMISSIONS_COMPANY = "SET_USER_PERMISSIONS_COMPANY";
export const SET_USER_PERMISSIONS_REPORTS = "SET_USER_PERMISSIONS_REPORTS";
export const SET_USER_PERMISSIONS_SELECT_ALL =
  "SET_USER_PERMISSIONS_SELECT_ALL";

export const SET_EMPLOYEE_ID = "SET_EMPLOYEE_ID";
export const SET_DEPENDENT_ID = "SET_DEPENDENT_ID";
export const SET_VEHICLE_ID = "SET_VEHICLE_ID";
export const SET_USER_ID = "SET_USER_ID";
export const SET_MANAGER_ID = "SET_MANAGER_ID";
export const ACCORDION_INDEX_COMPANY = "ACCORDION_INDEX_COMPANY";
export const ACCORDION_INDEX_SETTINGS = "ACCORDION_INDEX_SETTINGS";
export const ACCORDION_INDEX_EMPLOYEE = "ACCORDION_INDEX_EMPLOYEE";
export const TAB_INDEX_COMPANY_BASIC_DETAILS =
  "TAB_INDEX_COMPANY_BASIC_DETAILS";
export const TAB_INDEX_SETTINGS = "TAB_INDEX_SETTINGS";
export const TAB_INDEX_MISC = "TAB_INDEX_MISC";
export const TAB_INDEX_TEMPLATE = "TAB_INDEX_TEMPLATE";
export const TAB_INDEX_GRATUITY = "TAB_INDEX_GRATUITY";
export const TAB_INDEX_USERS = "TAB_INDEX_USERS";
export const SET_PAYROLL_HEADER_DATE = "SET_PAYROLL_HEADER_DATE";
export const SET_PAYROLL_BATCH_NAME = "SET_PAYROLL_BATCH_NAME";
export const SET_EMPLOYEE_NAME_CODE = "SET_EMPLOYEE_NAME_CODE";
export const SET_VEHICLE_NAME_CODE = "SET_VEHICLE_NAME_CODE";
export const SET_PAYROLL_HEADER_ID = "SET_PAYROLL_HEADER_ID";
export const SET_PAYROLL_ID = "SET_PAYROLL_ID";
export const SET_PAYROLL_VOUCHER_ID = "SET_PAYROLL_VOUCHER_ID";
export const SET_PAYROLL_HEADER_COMPARISON_ID =
  "SET_PAYROLL_HEADER_COMPARISON_ID";
export const SET_LOAN_SHOW_SELECT = "SET_LOAN_SHOW_SELECT";
export const SET_ASSET_SHOW_SELECT = "SET_ASSET_SHOW_SELECT";

export const SET_SELECT_TREE_VALUE = "SET_SELECT_TREE_VALUE";
export const SET_SELECT_COMPARISON_TREE_VALUE =
  "SET_SELECT_COMPARISON_TREE_VALUE";
export const SET_PAYROLL_HEADER_BATCH_SELECT_VALUE =
  "SET_PAYROLL_HEADER_BATCH_SELECT_VALUE";
export const SELECT_EMPLOYEES_ID_TO_PROCESS_PAYROLL =
  "SELECT_EMPLOYEES_ID_TO_PROCESS_PAYROLL";
export const SET_PAYROLL_IS_RELEASED = "SET_PAYROLL_IS_RELEASED";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_IS_OPEN_1 = "SET_IS_OPEN_1";
export const SET_IS_OPEN_2 = "SET_IS_OPEN_2";
export const SET_SHOW_VERIFY_LOGIN_OTP = "SET_SHOW_VERIFY_LOGIN_OTP";
export const SET_ATTENDANCE_MASTER_ID = "SET_ATTENDANCE_MASTER_ID";
export const SET_ATTENDANCE_EMPLOYEE_ID = "SET_ATTENDANCE_EMPLOYEE_ID";
export const SET_ATTENDANCE_MASTER_DATE = "SET_ATTENDANCE_MASTER_DATE";
export const SET_ATTENDANCE_YEAR = "SET_ATTENDANCE_YEAR";
export const SET_ATTENDANCE_MONTH = "SET_ATTENDANCE_MONTH";
export const SET_ATTENDANCE_IS_CLOSED = "SET_IS_ATTENDANCE_CLOSED";
export const IS_EMPLOYEES_EXIST = "IS_EMPLOYEES_EXIST";
export const SET_ATTENDANCE_STATUS_ID = "SET_ATTENDANCE_STATUS_ID";
export const SET_EMPLOYEE_STATUS_ID = "SET_EMPLOYEE_STATUS_ID";
export const SET_VEHICLE_STATUS = "SET_VEHICLE_STATUS";
export const SET_MASTER_ID = "SET_MASTER_ID";
export const SET_MASTER_NAME = "SET_MASTER_NAME";
export const SET_MASTER_COLUMN_NAME = "SET_MASTER_COLUMN_NAME";
export const SET_GRATUITY_MASTER_ID = "SET_GRATUITY_MASTER_ID";
export const SET_COMMISSION_MASTER_ID = "SET_COMMISSION_MASTER_ID";
export const FETCH_EMPLOYEES_DDL = "FETCH_EMPLOYEES_DDL";
export const FETCH_EMPLOYEES_STATUSES = "FETCH_EMPLOYEES_STATUSES";
export const FETCH_EMPLOYEE_EXCEL_IMPORTS = "FETCH_EMPLOYEE_EXCEL_IMPORTS";
export const SET_DATE = "SET_DATE";
export const SET_FROM_DATE = "SET_FROM_DATE";
export const SET_TO_DATE = "SET_TO_DATE";
export const SET_SET_FINAL_SETTLEMENT_REPORT =
  "SET_SET_FINAL_SETTLEMENT_REPORT";
export const SET_LOAN_ID = "SET_LOAN_ID";
export const SET_WPS_FILE_NAME = "SET_WPS_FILE_NAME";
export const SET_WPS_EXCEL_FILE_EXTENSION = "SET_WPS_EXCEL_FILE_EXTENSION";
export const SET_REQUEST_STATUS = "SET_REQUEST_STATUS";
export const SET_IS_EMPLOYEE_PERSONAL_DETAILS =
  "SET_IS_EMPLOYEE_PERSONAL_DETAILS";
export const SET_SELECTED_ACCORDION_REQUEST = "SET_SELECTED_ACCORDION_REQUEST";
export const SET_MASTER_FILTER_NAME = "SET_MASTER_FILTER_NAME";
export const SET_BANK_NAME_ROUTING_CODE = "SET_BANK_NAME_ROUTING_CODE";
export const SET_ROSTER_MASTER_ID = "SET_ROSTER_MASTER_ID";
export const SET_ROSTER_SELECT_TREE_VALUE = "SET_ROSTER_SELECT_TREE_VALUE";
export const SET_ROSTER_EMPLOYEE_ID = "SET_ROSTER_EMPLOYEE_ID";
export const SET_ROSTER_STATUS_ID = "SET_ROSTER_STATUS_ID";
export const SET_ROSTER_YEAR = "SET_ROSTER_YEAR";
export const SET_MASTER_PARAMETER_SEARCH_KEY =
  "SET_MASTER_PARAMETER_SEARCH_KEY";
export const SET_COMMISSION_AMOUNT = "SET_COMMISSION_AMOUNT";
export const SET_EVALUATION_MASTER_ID = "SET_EVALUATION_MASTER_ID";
export const SET_EVALUATION_EMPLOYEE_ID = "SET_EVALUATION_EMPLOYEE_ID";
export const SET_EVALUATION_FROM_DATE = "SET_EVALUATION_FROM_DATE";
export const SET_EVALUATION_TO_DATE = "SET_EVALUATION_TO_DATE";
export const SET_EVALUATION_IS_CLOSED = "SET_IS_EVALUATION_CLOSED";
export const SET_EVALUATION_TYPE_ID = "SET_EVALUATION_TYPE_ID";
export const SET_EVALUATION_TITLE = "SET_EVALUATION_TITLE";
